import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import colors from '../styles/colors'
import Button from './button'
import EstimateForm from './estimate-form'
import CardGeneral from './card-general'
import { Star } from 'react-feather'
import { monthNames } from '../helpers/all'

const ServiceHero = ({
  pageData,
  heroImage,
  serviceTitle,
  excerpt,
  specialOffer,
  offerDisclaimer,
  pageProps,
  badges,
}) => {
  const FORMOFFSETPX = 350

  const ContentContainer = styled.div``

  const ContentRow = styled.div``

  const ContentCol = styled.div`
    padding-top: 119px;
    padding-bottom: 60px;
    padding-right: 45px;
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 0px;
      padding-right: 25px !important;
      padding-left: 25px !important;
    }
  `

  const HeroSection = styled.section`
    height: auto;
    background-size: cover;
    border-bottom: 5px solid;
    position: relative;

    .gatsby-image-wrapper {
      &:before {
        content: '';
        position: absolute;
        height: 104%;
        width: 100%;
        background: #000;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.5;
      }
    }
  `
  const HeroHeading = styled.h1`
    position: relative;
    font-size: calc(1.25vw + 1.65rem);

    @media (min-width: 767px) {
      &:before {
        content: '';
        height: 3px;
        width: 50px;
        position: absolute;
        top: -0.5em;
      }
    }
  `

  const FormWrapper = styled.div`
    margin-top: 2em;
    @media (max-width: 767px) {
      margin-bottom: ${FORMOFFSETPX * -1}px;
    }
  `

  const HeroRating = styled.div`
    color: white;
    margin-top: 40px;

    font-weight: 600;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 20px;
    }
  `

  const HeroRatingStars = styled.div`
    svg {
      fill: #ffbc00;
      stroke: none;
    }
  `

  const HeroSectionOffset = styled.div`
    @media (max-width: 767px) {
      height: ${FORMOFFSETPX - 20}px;
    }
  `

  const FormHeading = styled.h3`
    padding-top: 15px;
  `

  const Tag = styled.div`
    background: #f6c530;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    align-items: center;
    color: white;
    text-transform: uppercase;
    padding: 5px;
    min-width: 30px;
    position: relative;
    position: absolute;
    top: 0;
    right: 21px;
    box-shadow: 0px 2px 5px 1px hsla(0, 0%, 0%, 0.12);
    transform: rotate(10deg);
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: -19px;
      width: 0;
      height: 0;
      top: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 19px solid #f5be18;
    }
    :before {
      content: '';
      background: white;
      height: 8px;
      width: 8px;
      position: absolute;
      right: 0;
      top: calc(50% - 4px);
      border-radius: 50%;
      right: -5px;
      z-index: 100;
    }
    @media (max-width: 480px) {
      &:after {
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
      }
    }
    p {
      margin: 0;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0 15px;
    }
  `
  const BadgeItemsContainer = styled.div`
    margin-top: 20px;
    align-items: center;
    flex-wrap: nowrap;
  `

  const BadgeItem = styled.div`
    flex: 0 0 25%;
    overflow: hidden;
    max-width: 110px;
    padding: 0.5em 0.75em;
    &:last-child {
      padding-right: 0;
    }
  `

  const BadgeImage = (props) => {
    return (
      <BadgeItem>
        <GatsbyImage
          image={getImage(props.item)}
          objectFit="contain"
          imgStyle={{ objectFit: 'contain' }}
          className="badgeImage"
          alt={props.item.title}
        />
      </BadgeItem>
    )
  }

  const Badges = (props) => {
    const badgeItems = badges.map((item, i) => (
      <BadgeImage item={item} key={i} />
    ))
    return (
      <BadgeItemsContainer className="d-flex">{badgeItems}</BadgeItemsContainer>
    )
  }

  const nextMonthFromCurrentDate = `${monthNames[(new Date().getMonth() + 1) % 12]} 3rd, ${new Date().getFullYear()}`

  return (
    <>
      <HeroSection className={'hero-Stacked border-color-primary service-hero'}>
        {heroImage ? (
          <GatsbyImage image={getImage(heroImage)} className="heroAreaImg" />
        ) : null}

        <ContentContainer className="container">
          <ContentRow className="row">
            <ContentCol className="col-lg-6 col-md-6">
              <HeroHeading className="before:background-secondary herohead">
                {serviceTitle}
              </HeroHeading>
              <p>{excerpt}</p>

              <HeroRating>
                <HeroRatingStars>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </HeroRatingStars>
                <span
                  className="rating-desc"
                  itemScope
                  itemType="https://schema.org/Product"
                >
                  <span itemProp="name">US Quality Construction</span>
                  <span
                    itemProp="aggregateRating"
                    itemScope
                    itemType="https://schema.org/AggregateRating"
                  >
                    {' '}
                    rated <span itemProp="ratingValue">4.9</span>/5 <br />
                    based on <span itemProp="reviewCount">
                      163
                    </span> reviews.{' '}
                  </span>
                </span>
              </HeroRating>
              {badges ? <Badges /> : null}
            </ContentCol>
            <div className="col-lg-2 d-none d-lg-block" />
            <FormWrapper className="col-lg-4 col-md-6">
              <CardGeneral>
                <FormHeading>
                  {specialOffer
                    ? specialOffer
                    : 'Start With A Free  ' + serviceTitle + ' Estimate!'}
                </FormHeading>

                <div className="specialsubheading">
                  *Offer Expires {nextMonthFromCurrentDate}{'. '}
                  {offerDisclaimer}
                </div>
                <p>
                Get Started By Scheduling A Complimentary, Zero-Commitment Consultation Below.
                </p>

                <EstimateForm
                  style={'minimal-hero'}
                  showPlaceHolders={true}
                  showLabels={false}
                  pageTitle={serviceTitle}
                  pageProps={pageProps}
                  pageLocation={
                    pageProps.location.href
                      ? pageProps.location.href
                      : 'Not provided'
                  }
                />
              </CardGeneral>
            </FormWrapper>
          </ContentRow>
        </ContentContainer>
      </HeroSection>
      <HeroSectionOffset />
    </>
  )
}

export default ServiceHero
